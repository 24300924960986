import dayjs from 'dayjs'

export const columns = [
	{
		prop: 'modelName',
		label: '车型名称',
		width: 300,
	},
	{
		prop: 'carNo',
		label: '自编号',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
	},
	{
		prop: 'color',
		label: '颜色',
	},
	{
		prop: 'driverName',
		label: '司机名称',
	},
	{
		prop: 'driverPhone',
		label: '司机手机号',
		/* formatter: function (row, column, cellValue, index) {
			return cellValue === 20 ? '上架' : '下架'
		}, */
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		/* formatter: function (row, column, cellValue) {
			return numeral(cellValue).divide(10000).format('0,0.00')
		}, */
	},
	{
		prop: 'monthlyRent',
		label: '月租（元）',
	},
	{
		prop: 'billDate',
		label: '账单日期',
		formatter: function (row, column, cellValue) {
			if (!cellValue) return '--'
			return dayjs(cellValue).format('YYYY-MM-DD')
		},
	},
	{
		prop: 'deferredDays',
		label: '欠租天数',
	},
	{
		prop: 'companyName',
		label: '所属公司',
	},
	{
		prop: 'createTime',
		label: '创建时间',
	},
	{
		prop: 'operation',
		label: '操作',
		fixed: 'right',
		width: 120,
	},
]

export const filters = {
	expand: true,
	filters: [
		{
			label: '车辆信息',
			attr: 'keyword',
			type: 'search',
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			clearable: true,
			width: 358,
		},
		{
			attr: 'driverSupportIds',
			label: '司服伙伴',
			type: 'multi-select',
			placeholder: '请选择司服伙伴',
		},
		{
			attr: 'deferredDays',
			label: '欠租天数',
			value: null,
			type: 'number',
			placeholder: '请输入欠租天数',
		},
		{
			attr: 'companyIds',
			label: '所属公司',
			type: 'multi-select',
			placeholder: '请选择所属公司',
		},
	],
}
/*
name: '',
	seriesId: '',
	brandId: '',
	fuelType: '',
	outerColors: '',
	status: '',
	indicativePrice: '',
*/

export const rules = {}
