<template>
	<el-dialog
		v-model="isShowDialog"
		width="700px"
		class="model-dialog-container"
		:title="dialogTitleComp"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading"></div>
		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" :disabled="isDisabled" @click="onSubmit">保 存</el-button>
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import Request from '@/http'
import { defineExpose, defineEmits, inject, reactive, getCurrentInstance, ref, computed } from 'vue'
import { rules } from '../config'

const formInstance = getCurrentInstance()

const $message = inject('$message')
const $messageBox = inject('$messageBox')

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)
const formInitValue = {
	name: '',
	seriesId: '',
	brandId: '',
	fuelType: '',
	outerColors: '',
	productiveYear: '',
	status: '',
	indicativePrice: undefined,
}
const formData = reactive({})
const formRule = rules
const statusOptions = [
	{ name: '下架', code: 10 },
	{ name: '上架', code: 20 },
]

const brandOptions = ref([])
const seriesOptions = ref([])
const emit = defineEmits(['refresh'])

const formType = ref('create')
let waitUpdateId = ''

const dialogTitleComp = computed(() => {
	switch (formType.value) {
		case 'create':
			return '新增车型'
		case 'update':
			return '编辑车型'
		default:
			return '新增车型'
	}
})

function onBrandChange(e, needInitSeries = true) {
	console.log('onBrandChange ==>', e)
	if (!e) {
		return
	}
	needInitSeries && (formData.seriesId = '')
	const selectedBrandItem = brandOptions.value.find(option => option.id === e)
	console.log('找到的车系列表 ==>', selectedBrandItem)
	if (selectedBrandItem) {
		seriesOptions.value = selectedBrandItem.children
	}
}
function onSeriesChange(e) {
	console.log('onSeriesChange ==>', e)
}

function openBrandSeriesForm() {
	formInstance.refs.seriesTreeFormRef.create()
}

async function refreshBrandOptions() {
	await getBrandSeriesTree()
	onBrandChange(formData.brandId)
}

async function getBrandSeriesTree() {
	return Request({
		url: 'suf4-user-service/sysCarBrandSeries/tree',
		method: 'POST',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				console.log('品牌车系树获取成功 ==>', res.data)
				brandOptions.value = res.data
				return true
			} else {
				throw new Error(res.msg)
			}
		})
		.catch(error => {
			console.log('获取品牌树失败 ==>', error)
			return false
		})
}

function create() {
	formType.value = 'create'
	getBrandSeriesTree()
	isShowDialog.value = true
}

async function update(item) {
	formType.value = 'update'
	waitUpdateId = item.id
	console.log('即将进行编辑 ==>', item)
	Object.assign(formData, item)
	formData.outerColors = item.outerColors.split(',')
	await getBrandSeriesTree()
	onBrandChange(item.brandId, false)
	isShowDialog.value = true
}

function deleteRole() {
	$messageBox
		.confirm('确定将删除该条数据？', '提示', {
			type: 'warning',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		})
		.then(() => {})
		.catch(() => {})
}

function setAuth() {
	formType.value = 'setAuth'
	isShowDialog.value = true
}

async function onSubmit() {
	try {
		isLoading.value = true
		const formValidRes = await formInstance.refs.ruleFormRef.validate()
		if (!formValidRes) {
			throw new Error('表单校验失败, 请检查!')
		}
		console.log('表单校验成功 ==>', formValidRes, formData)
		let requestUrl = ''
		const params = formData
		if (formType.value === 'create') {
			delete params.id
			requestUrl = `suf4-user-service/sysCarModel/create`
		} else {
			requestUrl = `suf4-user-service/sysCarModel/update`
			params.id = waitUpdateId
		}
		console.log('表单内容 ==>', params)
		const updateRes = await Request({
			url: requestUrl,
			method: 'POST',
			data: {
				...params,
				outerColors: params.outerColors.join(','),
			},
		})
		if (updateRes.code === 200) {
			$message.success(`${formType.value === 'create' ? '创建' : '更新'}成功!`)
			isLoading.value = false
			onClose()
			emit('refresh')
		} else {
			throw new Error(updateRes.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

function onClose() {
	Object.assign(formData, formInitValue)
	isShowDialog.value = false
}

defineExpose({ create, update, deleteRole, setAuth })
</script>

<style lang="less">
.number-input-left {
	.el-input__inner {
		text-align: left;
	}
}
</style>
<style lang="less" scoped>
.unit-box {
	width: 40px;
	height: 40px;
	.flexible(row, center, center);
}

.short-box {
	width: 200px;
}

.brand-button {
	margin-left: 12px;
}
.model-dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
	}
}
</style>
